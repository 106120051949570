<template>
  <div class="text 111">
    <div class="contain">
      <div
        v-if="content.primersTitle"
        style="
          padding: 0 0 0.52083vw 0;
          font-size: 1.45833vw;
          color: #333333;
          font-weight: 500;
        "
      >
        {{ content.primersTitle }}
      </div>
      <div
        class="title"
        v-html="content.title"
        style="color: #333333 !important"
      ></div>
      <div
        v-if="content.subTitle"
        style="
          padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;
          font-size: 1.45833vw;
          color: #333333;
          text-align: right;
          font-weight: 500;
        "
      >
        {{ content.subTitle }}
      </div>
      <div
        class="content_source"
        style="padding: 0.83333vw 0 0 0; font-size: 0.83333vw; color: #6c6a6a"
      >
        <el-divider direction="vertical"></el-divider>
        <span v-if="content.mapProperties.wenzi"
          >{{ $t('source_4') }} ：{{ content.mapProperties.wenzi }}</span
        >
        <span v-if="content.mapProperties.sheying"
          >{{ $t('source_3') }} ：{{ content.mapProperties.sheying }}</span
        >
        <span v-if="content.author">{{ $t('source_2') }} ：{{ content.author }}</span>
        <span v-if="content.origin"
          >{{ $t('source_1') }} ：{{ content.origin }}</span
        >
        <span v-if="content.publishTime">{{
          $moment(content.publishTime).format('YYYY-MM-DD')
        }}</span>
      </div>
      <!-- <div class="info">
        <div class="info_left">
        </div>
        <div class="info_right">
          <div class="info_right_div1">
          </div>
        </div>
      </div> -->
      <div
        class="content 1"
        :style="`fontSize:${fontsize1}px;color:#333;`"
        v-html="content.content"
      ></div>
    </div>
    <!-- <div class="nav">
      <div>上一篇：佳节梧桐下 青春正风华——欧洲学院中秋系列主题活动圆满举办</div>
      <div>下一篇：“国际传播与北京叙事”学术研讨会在京举行</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    content: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      fontsize1: '20',
    }
  },
  watch: {
    // content:function (val){
    //   val
    //   debugger
    // }
  },
  mounted() {
    if (this.content) {
      this.content.primersTitle = ''
      this.content.subTitle = ''
      this.getDetails(this.content.id)
    }
  },
  created() {},
  methods: {
    changFontsize(type) {
      this.fontsize1 = type == 1 ? '24' : type == 2 ? '20' : '16'
    },
    async getDetails(id) {
      const res = await this.API.basic.getManuscriptDetails(id)
      this.content.primersTitle = res.data.primersTitle || ''
      this.content.subTitle = res.data.subTitle || ''
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #002bfd !important;
}
img {
  max-width: 100%;
}
.content_source {
  span {
    padding-right: 16px;
  }
}
.text {
  .contain {
    padding: 0 195px;
  }
  .title {
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    text-align: center;
    span {
      color: #333333 !important;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 43px;
    .info_left {
      height: 45px;
      font-size: 14px;
      font-family: NotoSansHans;
      color: #787878;
      line-height: 20px;
      padding-left: 17px;
      // border-left: 1px solid #ccc;
      span {
        margin-right: 15px;
      }
    }
    .info_right {
      .info_right_div1 {
        display: flex;
        img {
          width: 42px;
          height: 42px;
        }
        .shareImg {
          margin-right: 18px;
        }
      }
      .info_right_div2 {
        height: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
        span {
          cursor: pointer;
          margin: 0 3px;
        }
        span:hover {
          color: #a12916;
        }
      }
    }
  }
  .content {
    margin-top: 37px;
    margin-bottom: 50px;
    line-height: 1.5em;
    
    ::v-deep img {
      margin: auto !important;
      max-width: 600px!important;
    }
    ::v-deep video {
      max-height: 500px;
    }
  }

  .nav {
    display: flex;
    height: 80px;
    border-top: 1px solid #eee;
    > div {
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
    }
    > div:hover {
      color: #a12916;
    }
    > div:first-of-type {
      border-right: 1px solid #eee;
    }
  }
}
.active1 {
  font-size: 15px;
  color: #a12916;
}
</style>
