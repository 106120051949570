<template>
  <div class="pagination">
    <el-pagination
    background
      @current-change="handleCurrentChange"
      :current-page="currentPage + 1"
      layout="prev, pager, next, total"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    totalNum: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    currentPage: {
      type: Number,
    },
  },
  data() {
    return {
      size: 10,
      total: 100,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$emit("pageChange", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
